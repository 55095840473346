
.wrap {padding:46px 0 73px; background: #f8f8f8}
/* 로딩 스피너 */
.wrap .css-tj5bde-Svg {width: 10px; height: 10px;}
.absoluteCenter {position: absolute; top:50%; left:50%; transform: translate(-50%, -50%);}
.wrap .slick-slide img {display: inline-block;}

/* 토스트 팝업 */
.wrap .Toastify__toast-body {padding-right:16px; cursor: initial;}
.wrap .Toastify__toast-container {max-width: 95%; margin:10px auto; right:0;}
.wrap .Toastify__toast-container--bottom-left {bottom:3em; left:1em; width: auto;}
.wrap .Toastify__close-button {position: absolute; top:50%; transform: translateY(-50%); right:20px; }
.wrap .Toastify__toast > img {margin-right: 5px;}
.wrap .Toastify__toast {font-size:14px; line-height: 1.4; color:#333333; font-weight: 500; max-width: 100%; box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);}
.wrap .Toastify__toast + .Toastify__toast {margin-top: 10px;}

.wrap .Toastify__toast-icon {display: none;}
.wrap .Toastify__toast a {color:#0f62fe;}
.wrap .Toastify__toast button {margin-top: -4px;}
.wrap .Toastify__toast button:hover {filter: brightness(220%);}
.wrap .Toastify__toast button.co1 {color: #da1e28;}
.wrap .Toastify__toast:before {content:''; position: absolute; left: 0; top: 0; width: 3px; height:100%; background:#0043CE; }
.wrap .toast_flex {display: flex; justify-content: space-between; gap: 0 10px;}
.wrap .toast_flex > div {display: flex; align-items: center;}

.wrap .toast_flex img {margin-right:10px;}

.wrap .Toastify__toast--info {background: #FFFFFF; border:1px solid #8eaeeb;}
.wrap .Toastify__toast--success {background: #FFFFFF; border:1px solid #94d7a7;}
.wrap .Toastify__toast--success:before {background: #24a148}
.wrap .Toastify__toast--warning {background: #FFFFFF; border:1px solid #f9e292;}
.wrap .Toastify__toast--warning:before {background: #f1c21B;}
.wrap .Toastify__toast--error {background: #FFFFFF; border:1px solid #f09da1;}
.wrap .Toastify__toast--error:before {background: #da1e28;}

.login_area {position: fixed; top:0; left:0; width: 100%; display: flex; flex-direction: column; align-items: center; height: 100vh; padding:45vh 5vh 10vh; background: #fff url("../../../public/assets/img/login_topbg.png") no-repeat top left / 90vw auto;}

.login_area .login_tit {position: absolute; top:10vh; left:5vh;}
.login_area .login_tit p {font-size:4.4vw; color: #000;}
.login_area .login_tit h1 {font-size:11vw; font-weight: 600; color: #000}

.login_area .logo {font-size:45px; color: #111; font-weight: 500; margin-bottom: 10vh;}
.login_area .login_box {position: relative; width: 100%;}
.login_area .error_box {position: relative;}
.login_area .error_box ~ .error_box {padding-top: 30px;}
.login_area .error_box .error_tip {position: absolute; bottom:-20px; left:22px; font-size:11px; color: #f04736; margin-top: 5px;}

.login_area .input_flex {position: relative; width: 100%; display: flex; overflow: hidden; align-items: center; border:1px solid #EDEDEDFF;  border-radius: 100px; padding: 17px 25px; box-shadow:5px 5px 10px rgba(0,0,0,0.05); background: #fff;}
.login_area .input_flex.success {border-color: #f8bd3c;}
.login_area .input_flex .tit {color: #8c8c8c; font-size:16px; min-width: 80px;}
.login_area .input_flex input {border:none; font-size:16px; width: inherit; background: transparent;}
.login_area .input_flex input::-webkit-input-placeholder {font-size:16px;}
.login_area .input_flex .timer {color: #ff0000; font-size:13px; margin-right:5px;}
.login_area .login_box .parent_layer {position: absolute; background: #fff; width: 100%; border:1px solid #f8bd3c; border-radius: 10px; top: 0; padding:80px 20px 80px; opacity: 0; pointer-events: none; z-index: 9; box-shadow:5px 5px 10px rgba(0,0,0,0.05);}
.login_area .login_box .parent_layer.on {opacity: 1; pointer-events: inherit;}
.login_area .login_box .parent_layer button {white-space: nowrap; padding:2px 15px 4px; border-radius: 25px; background:#333; color: #fff; }
.login_area .login_box .parent_layer .input_flex {border:none; border-bottom:1px solid #e8e8e8; border-radius: 0; padding:0 0 10px; box-shadow: none;}
.login_area .login_box .parent_layer .input_flex + .input_flex {margin-top: 40px;}
.login_area .login_box .parent_layer .input_flex .tit {min-width: 100px;}

.login_area #loginForm {width: 100%; max-width: 500px;}
.login_area #loginForm > button {background: #f8bd3c; color: #fff; width: 100%; border: 2px solid #f8bd3c; border-radius: 50px; margin-top:80px; padding: 13px 0; font-size:17px;}
.login_area #loginForm > button.off {opacity: 0.2}
.login_area .sub_menu {margin-top: 20px; display: flex; align-items: center; gap:0 10px;}
.login_area .sub_menu button {color:#8d8d8d; display: flex; align-items: center;}
.login_area .sub_menu button + button:before {content:''; display: block; width: 1px; height: 12px; background: #8d8d8d; margin-right: 10px;}
.login_area .footer {margin-top: 20vh; color: #8d8d8d; font-size:12px; position: static; border:none; width: auto;}

.splash_area {position: fixed; top:0; left:0; width: 100vw; height: 100vh; display: flex; flex-direction: column; align-items: center; padding-top: 15vh;  background-position: bottom right, center; background-repeat: no-repeat; background-size: contain, cover; z-index: 9999}
.splash_area img {width: 100px; height: 130px;
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-name: heartBeat;
    animation-name: heartBeat;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}
.splash_area p {color: #fff; font-size:22px; text-align: center; margin-top: 20px; font-weight: 500; line-height: 1.6;}
.splash_area .btn_box {display: flex; flex-direction: column; gap:20px 0; margin-top: 30px;}
.splash_area button {background: #fff; border-radius: 50px; width: 240px; height: 50px; font-size:17px; font-weight: 500; box-shadow: 5px 5px 5px rgba(0,0,0,0.3)}

.splash_area_single {display: flex; position: fixed; top:0; left:0; width: 100vw; height: 100vh; flex-direction: column; align-items: center; justify-content: center; background-image: url("../../../public/assets/img/multi_btmimg.png"); background-position: bottom right; background-repeat: no-repeat; background-size:65%; z-index: 9999; background-color: #FFFFFF;

}
.splash_area_single p {color: #111; font-size:22px; text-align: center; font-weight: 500; line-height: 1.6; margin-bottom: 30px; white-space: break-spaces;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}
/*.splash_area_single.off {display: none; pointer-events: none;}*/
.splash_area_single img {display: block; max-width: 100%; width: 60%; margin: 0 0 10vh;
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-name: splashFade;
    animation-name: splashFade;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}

/* react-select */
.wrap .css-13cymwt-control {border-radius: 2px;}
.wrap .css-1u9des2-indicatorSeparator {display: none;}
.wrap .css-1nmdiq5-menu {margin:1px 0;}
.wrap .css-qbdosj-Input {color: #a8a8a8}
.wrap .css-fkbdul-option {font-size:14px;}
.wrap .css-1dimb5e-singleValue {font-size:14px; color: #a8a8a8}
.wrap .css-wwuvxn-option {font-size:14px;}
.wrap .css-1ck4iaz-option {font-size:14px;}
.wrap .css-1ufbu83-option {font-size:14px;}
.wrap .css-1fdsijx-ValueContainer input {font-size:14px;}
.wrap .css-1nmdiq5-menu {z-index: 9;}

.header {position: fixed; top:0; left:0; width: 100%; z-index: 999; background:#fff; display: flex; align-items: center; justify-content: space-between; padding:10px 20px; border-bottom:1px solid #e5e5e5}
.header .left {display: flex; align-items: center; gap: 0 10px; position: relative;}
.header .left h3 {color: #000; font-size:17px; user-select: none;}
.header .left > button {color: #000; font-size:17px;}
.header .right {color: #000; font-size:13px; position: relative;}
.header .right span {cursor: pointer;}
.header .layer {position: absolute; top:100%; right:0; background: #fff; border-radius: 4px; z-index: 99; box-shadow:0 3px 4px rgba(0,0,0,0.1); opacity: 0; pointer-events: none; transition: 0.3s ease-in-out; width: min-content;}
.header .left .layer {right:auto; left:0;}
.header .layer.on {opacity: 1; pointer-events: inherit;}
.header .layer button {padding:8px 25px; white-space: nowrap; font-size:14px; color: #000;width: 100%;}
.header .layer button + button {border-top: 1px solid #e5e5e5;}

.main_top_area {background: #f8f8f8; padding:20px 0;}
.main_top_area .top {display: flex; align-items: center; justify-content: center; gap: 0 15px;}
.main_top_area .top .right .css-b62m3t-container {min-width: 160px}
.main_top_area .left .img {width: 62px; height: 62px; border-radius: 50%; overflow: hidden; background: #FFFFFF;}
.main_top_area .left .img img {display: block; width: 100%; object-fit: cover;}
.main_top_area .right .teacher {font-size:14px; color: #000; margin-top: 3px;}
.main_top_area .btm {position: relative; overflow: hidden}
.main_top_area .btm > ul {margin-top: 20px;}
.main_top_area .btm .slick-list {position: relative; overflow: inherit; padding:0 0 10px !important}
.main_top_area .btm .slick-list:after {content: ''; display: block; width: 200%; height: 1px; background: #d2dfe3; position: absolute; bottom:7px; left:0; z-index: -1}
.main_top_area .btm .slick-slide > div > div {position: relative; aspect-ratio: 100/20; text-align: center; font-size:16px; color: rgba(0,0,0,0.8); margin-right:-4px; padding-bottom: 10px; cursor: pointer;}
.main_top_area .btm .slick-slide > div > div.on:before {content:''; display: block; width: 0; height: 0; border-radius: 50%; border: 7px solid #57626a; position: absolute; bottom:-9px; left:50%; transform: translateX(-50%);}
.main_top_area .btm .slick-slide > div > div.on:after {content:''; display: block; width: 0; height: 0; border-radius: 50%; border: 4px solid #fff; position: absolute; bottom:-6px; left:50%; transform: translateX(-50%);}
.main_top_area .btm .slick-slide > div > div.on.co2:before {bottom:-10px;}
.main_top_area .btm .slick-slide > div > div.on.co2:after {bottom:-7px}

.main_top_area .btm .slick-slide > div > div .point img {width:4vw; min-width: 20px; max-width: 40px; position: relative; top:-2px;}
.main_top_area .btm .slick-slide > div > div .point {margin-right: -3px; margin-bottom: 3px;}
.main_top_area .btm .slick-slide > div > div.co1 .point img {color: #6facce; filter: invert(64%) sepia(31%) saturate(476%) hue-rotate(157deg) brightness(94%) contrast(95%);} /* 제로 && 진단 테스트 */
.main_top_area .btm .slick-slide > div > div.co2 .point img {color: #000000;} /* 일일 테스트 */
.main_top_area .btm .slick-slide > div > div.co3 .point img {color: #e76262; filter: invert(67%) sepia(18%) saturate(2395%) hue-rotate(314deg) brightness(89%) contrast(105%);} /* 오답 테스트 */
.main_top_area .btm .slick-slide > div > div.co4 .point img {color: #86c640; filter: invert(64%) sepia(79%) saturate(354%) hue-rotate(46deg) brightness(95%) contrast(88%);} /* 유사 테스트 */
.main_top_area .exam_title {padding:3px 15px 0; display: flex; align-items: center; justify-content: center; gap: 0 7px;}
.main_top_area .exam_title img {width: 36px; display: none;}
.main_top_area .exam_title .tit_box {display: flex; gap: 0 4px; font-size:13px; font-weight: 500; color: rgba(0,0,0,0.4);}
.main_top_area .exam_title .tit_box .sub_tit.co1 {color: #6facce;} /* 제로 && 진단 테스트 */
.main_top_area .exam_title .tit_box .sub_tit.co2 {color: #000000;} /* 일일 테스트 */
.main_top_area .exam_title .tit_box .sub_tit.co3 {color: #e76262;} /* 오답 테스트 */
.main_top_area .exam_title .tit_box .sub_tit.co4 {color: #86c640;} /* 유사 테스트 */

.main_btm_area {}
.main_btm_area .exam_tab_menu {display: flex; gap: 0 10px; padding: 0 10px 10px; margin-bottom:-10px; position: relative;}
.main_btm_area .exam_tab_menu li {flex:1; margin-top: 20px; padding: 5px 10px 7px; text-align: center; font-size:14px; color: #999; position:relative; cursor: pointer; border:1px solid #e5e5e5; border-radius: 20px;}
.main_btm_area .exam_tab_menu li.on {background: #dbefee; border:2px solid #b9dedb; color: #000; box-shadow: none;}
.main_btm_area .exam_tab_content {padding-bottom: 120px; margin-bottom: -73px}
.main_btm_area .exam_tab_content .exam_tab_table_top {display: flex; justify-content: space-between; align-items: center; padding:10px 10px 0;}
.main_btm_area .exam_tab_content .exam_tab_table_top .left {font-size:15px; color: #000; display: flex; align-items: center; gap: 0 4px; white-space: nowrap;}
.main_btm_area .exam_tab_content .exam_tab_table_top .right {display: flex; gap: 0 4px;}
.main_btm_area .exam_tab_content .exam_tab_table_top .right span {min-width: 60px; border-radius: 2px; font-size:13px; color: #fff; text-align: center; padding:4px 6px 5px;}
.main_btm_area .exam_tab_content .exam_tab_table_top .right span.correct {color: #1db1ee}
.main_btm_area .exam_tab_content .exam_tab_table_top .right span.incorrect {color: #ee2525}
.main_btm_area .exam_tab_conbox {background-color: #fff; border: 1px solid #ddd; border-radius: 10px; margin: 10px;}


.no_test_box {display: flex; height: calc(100vh - 175px); flex-direction: column; justify-content: center; align-items: center; border-top: 1px solid #e5e5e5}
.no_test_box img {width: 30vw; max-width: 90px;}
.no_test_box p {font-size:19px; margin-top: 20px;}
.no_test_box span {font-size:15px; color: #666; margin-top: 5px;}

.exam_tab_table {width: 100%; margin: 10px 0 0;}
.exam_tab_table thead th {height: 30px; border-bottom:1px solid #aaa; padding:4px 8px; line-height:1.3; font-weight: 400; font-size:13.2px; white-space: nowrap;}
.exam_tab_table tbody td {height: 40px; border-bottom: 1px solid #e8e8e8; font-size:13.2px; line-height: 1.4; text-align: center; padding:4px 8px; word-break: keep-all;}
.exam_tab_table tbody td.txt_l {text-align: left;}
.exam_tab_table tbody td.txt_r {text-align: right;}
.exam_tab_table tbody td.co1 {color: #8d8d8d;}
.exam_tab_table tbody td .txt_level {color: #c845e0; white-space: nowrap;}
.exam_tab_table tbody td .fa-o {color:#037aff}
.exam_tab_table tbody td .fa-x {color:#ff0000}

.answer_panner_list {margin: 10px 10px 0; border-radius: 10px; border:1px solid #ddd; overflow: hidden;}
.answer_panner_list li {padding:20px 20px; background: #FFFFFF;}
.answer_panner_list li img {max-width: 100%;}
.answer_panner_list li + li {border-top: 7px solid #e5e5e587;}
.answer_panner_list li .num {font-size:16px; display: block; margin-bottom: 6px; position: relative;}
.answer_panner_list li .num:before {content:''; position: absolute; top:0; left:-15px; width: 84px; height: 58px; background: url("../../../public/assets/img/mark_answer.png") no-repeat; background-size: 45%;}
.answer_panner_list li .num.wrong:before {background-image: url("../../../public/assets/img/mark_wrong.png"); left:-5px; top: -10px; }
.answer_panner_list li .answer_box .button_box {display: flex; justify-content: flex-end; margin-top: 30px;}
.answer_panner_list li .answer_box .button_box button {display: flex; align-items: center; gap: 0 7px; padding:6px 13px 6px; background: #bbb; border-radius: 2px; color: #fff; font-size:14px; transition: 0.3s ease-in-out; white-space: nowrap;}
.answer_panner_list li .answer_box .button_box button svg {font-size:13px; margin-top: 3px;}
.answer_panner_list li .answer_box .button_box.on button {background: #6aaffa; border-bottom-right-radius: 0; border-bottom-left-radius: 0;}
.answer_panner_list li .answer_box .button_box.on button svg {transform: rotate(180deg); margin-top: 0;}
.answer_panner_list li .answer_box .answer_img_box {display: none; border:1px solid #6aaffa; padding:15px 10px;}
.answer_panner_list li .answer_box .button_box.on + .answer_img_box {display: block;}

#topBtn {transition: 0.3s ease-in-out; opacity: 0; pointer-events: none; position: fixed; left:50%; transform: translateX(-50%); bottom:13px; width: 34px; height: 34px; display: flex; align-items: center; justify-content: center; border-radius: 50%; background: rgba(255,255,255,0.9) none repeat; border:1px solid #ddd; color: #333; z-index: 10; text-align: center;}
#topBtn.on {opacity: 1; pointer-events: inherit;}
#topBtn img {display: block; width: 10px;}

@keyframes heartBeat {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8)
    }

    14% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    28% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    42% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    70% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes splashFade {
    0% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@media (min-width: 380px) {
    .login_area {background-size: 370px auto;}
}

@media (min-width: 370px){
    .login_area .login_box .parent_layer button {font-size:15px;}
}

@media (max-width: 370px) {
    .login_area {
        padding: 45vh 4vh 10vh;
    }

    .main_top_area .btm .slick-slide > div > div {font-size:13px;}

    .login_area .login_box .parent_layer {
        background: #fff;
        border: 1px solid #f8bd3c;
        border-radius: 10px;
        box-shadow: 5px 5px 10px rgba(0,0,0,.05);
        opacity: 0;
        padding: 80px 15px;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 9;
    }

    .login_area .input_flex .tit {
        color: #8c8c8c;
        font-size: 15px;
        min-width: 80px;
    }

    .login_area .input_flex input {
        background: transparent;
        border: none;
        font-size: 15px;
        width: inherit;
    }
}


@media (min-width: 768px) {

    /* 로그인 */
    .login_area .login_tit p {font-size:17px;}
    .login_area .login_tit h1 {font-size:40px;}
    .login_area .logo {font-size:60px;}
    .login_area .input_flex ~ .input_flex {margin-top: 40px;}
    .login_area .parent_layer .input_flex ~ .input_flex {margin-top: 20px;}
    .login_area .input_flex .tit {font-size:18px;}
    .login_area .input_flex input {font-size:18px;}
    .login_area .input_flex input::-webkit-input-placeholder {font-size:16px;}
    .login_area .input_flex .error_tip {font-size:14px; bottom: -25px;}
    .login_area #loginForm > button {font-size:21px;}
    .login_area .footer {font-size:16px;}
    .login_area .input_flex .tit {min-width: 150px;}
    .login_area .login_box .parent_layer button {font-size:17px; padding:4px 20px 6px;}
    .login_area .input_flex .timer {font-size:18px; margin-right:25px;}

    .wrap .css-1dimb5e-singleValue {font-size:16px;}
    .wrap .css-wwuvxn-option {font-size:15px;}
    .wrap .css-1ufbu83-option {font-size:15px;}
    .wrap .css-1ck4iaz-option {font-size:15px;}
    .main_btm_area .exam_title {padding:15px;}
    .main_btm_area .exam_title .tit_box {font-size:20px;}

    .header {padding:20px;}
    .header .left h3 {font-size:30px;}
    .header .left > button {font-size:22px;}
    .header .right {font-size:20px;}
    .main_top_area {padding:50px 0 0;}
    .main_top_area .left .img {width: 120px; height:120px;}
    .main_top_area .right .teacher {font-size:18px;}
    .main_top_area .btm > ul li {font-size:22px; font-weight: 500; min-width: 20%;}
    .main_top_area .btm .slick-list {margin-top: 50px;}
    .main_top_area .btm .slick-slide > div > div {font-size:24px; aspect-ratio: 100/22;}
    .main_btm_area .exam_tab_content .exam_tab_table_top .left {font-size:20px}
    .main_btm_area .exam_tab_content .exam_tab_table_top .right {gap:0 10px;}
    .main_btm_area .exam_tab_content .exam_tab_table_top .right span {font-size:17px; padding:3px 10px 5px;}
    .main_btm_area .exam_tab_menu li {font-size:22px; padding:10px 12px 13px; border-radius: 100px;}

    .exam_tab_table thead th {font-size:18px;}
    .exam_tab_table tbody td {font-size:17px; padding:4px 20px; height: 60px}

    .header .layer button {font-size:20px; padding:10px 40px;}
    .answer_panner_list li {padding:40px 10%;}
    .answer_panner_list li .answer_box .button_box button {font-size:20px;}

    .answer_panner_list li .num {font-size:40px; font-weight: 500;}
    .answer_panner_list li .num:before {left:-27px; background-size: 94%}
    .answer_panner_list li .num.wrong:before {background-size: 85%; left:-5px;}

    .main_top_area .btm .slick-list:after {bottom:9px;}
    .main_top_area .btm .slick-slide > div > div.on:before {border-width: 10px; bottom: -9px;}
    .main_top_area .btm .slick-slide > div > div.on:after {border-width: 6px; bottom: -5px;}
    .main_top_area .btm .slick-slide > div > div.on.co2:before {bottom:-12px;}
    .main_top_area .btm .slick-slide > div > div.on.co2:after {bottom:-8px}
    .main_btm_area .exam_tab_menu {gap: 0 20px;}

    .main_top_area .exam_title .tit_box {font-size:25px; padding-bottom: 20px;}
}

@media (min-width: 1150px) {
    .main_top_area .btm .slick-slide > div > div {padding-top: 30px;}
    .main_top_area .btm .slick-slide > div > div.on.co2:before {bottom:-15px;}
    .main_top_area .btm .slick-slide > div > div.on.co2:after {bottom:-11px}
}

@media (max-width: 390px) {
    .main_btm_area .exam_tab_menu li {font-size:14px; white-space: nowrap;}
}